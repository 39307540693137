.phraseCreator {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  /* width: 100%; */
  height: 100%;
  padding: 10px;
  background: #1A1A1A;
}

.editor {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  /* width: 100%; */
  width: 303px;  
  height: 100%;
  padding: 10px;
  background: #1A1A1A;
}

.editor__text {
  padding: 0;
  font-size: 20px;
  font-family: Roboto;
  text-align: center;
  height: 50px;
  white-space: nowrap;
  overflow: auto;
}

.editor__size {
  font-size: 20px;
  font-family: Roboto;
  position: absolute;
  left: -999vw;
  top: -990vw;
}

.editor__preview {
  width: 100%;
  border: 1px solid white;
}