.cookie {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  bottom: 10px;
  z-index: 99999999;
  display: flex;
  justify-content: center;
  display: none;
}


.cookie__display {
  display: flex;
}


.cookie__content {
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.80);
  padding: 23px 17px 13px;
  width: calc(100% - 60px);
  color: #FFF;
  font-family: "Roboto Flex";
  font-size: 14px;
  box-sizing: border-box;
}

.expla {
  margin-bottom: 10px;
}

.isok {
  margin-bottom: 21px;
  /* text-decoration: none; */
  color: #FFF;
  display: block;
}

@media only screen and (max-width: 499px) {
  .cookie__content {
    width: 70vw;
    /* background-color: red;
    border: 5px red solid; */
  }
}

@media only screen and (min-width: 500px; max-width: 991px) {
  .cookie__content {
    width: 95%;
    /* background-color: green;
    border: 5px green solid; */
  }
}


@media only screen and (min-width: 992px) {
  .cookie {
    max-width: 800px;
    /* background-color: green; */
  }
}