.error {
  width: 100%;
  background-color: #e36e6e;
  position: relative;
  animation: bounce; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2s; /* don't forget to set a duration! */
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  font-fammin: 'Roboto', sans-serif;
}