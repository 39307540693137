.tape {
  background-image: url('../assets/tape.png');
  width: 100%;
  height: 53.6px;
  position: absolute;
  bottom: 0px;
  background-position: -20px;
  bottom: -29px;
  z-index: 999999;
}


@media only screen and (min-width: 992px) {
  .tape {
    display: none;
  }
}