.selectBg {
  margin-top: 43px;
  padding-bottom: 81px;
  position: relative;
}

.imgItem {
  background: none;
  border: none;
  padding: 0;
  flex-shrink: 0;
}

.imgItem__img {
  border-radius: 10px;
  background-image: url(../assets/bg/bg-rects.png);
}

/* 64 304 24 (38) (430)
392 */

.list {
  display: flex;
  gap: 6%;
  overflow: scroll;
  padding: 0 0 0 14.9%;
  overflow-y: hidden;
  overflow-x: auto;
}

.header {
  color: #FFF;
  text-align: center;
  text-transform: uppercase;
  font-family: "Roboto Flex";
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
  margin: 0;
  margin-bottom: 20px;
}


@media only screen and (min-width: 992px) {
  .imgItem__img {
    height: calc(100vh - 60px);
    max-height: 900px;
  }
}

@media only screen and (max-width: 991px) {
  .imgItem__img {
    width: 100%;
  }

  .imgItem {
    width: 81%;
  }
}