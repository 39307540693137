.header {
  color: #FFF;
  font-family: "Roboto Flex";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  padding: 0px;
  margin: 0px;
}