.welcome {
  width: 100%;
  height: var(--app-height);
  background-image: url('../assets/img/bg.svg');
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
}

.welcome_hide {
  /* display: none; */
  height: fit-content;
}

.logo {
  margin-top: 3.472222222%;
  height: 11.80555556%;
  max-width: 100%;
}

.logo_hide {
  margin-bottom: 25px;
}

.chibi {
  margin-top: 5.787037037%;
  height: 37.73148148%;
  max-width: min(100%, 400px);
  height: auto;

}

.uniq {
  height: 17.47685185%;
  max-width: 100%;
}

.create {
  /* margin-top: 5.671296296%; */
  text-transform: uppercase;
  /* max-height: 5.439814815%; */
  /* margin-bottom: 12.61574074%; */
  width: fit-content;
  padding: 18px 32px;
  box-sizing: border-box;
  font-size: 20px;
}

@media only screen and (max-width: 991px) {
  .create {
    margin-top: 5.671296296%;
  }

  .create {
    margin-bottom: 12.61574074%;
  }

}

@media only screen and (min-width: 992px) {
  .welcome {
    background-image: none;
  }


}