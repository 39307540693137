.nextBack {
  display: flex;
  padding: 0 48px;
  /* gap: 12px; */
  box-sizing: border-box;
  font-size: 20px;
}

.buttonBack {
  max-width: 39%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 47px;
}

.buttonNext {
  width: 57%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 47px;
}


.nextImg {
  height: 30px;
}


@media only screen and (max-width: 991px) {
  .nextBack {
    justify-content: space-between;
  }
}

@media only screen and (min-width: 992px) {
  .nextBack {
    justify-content: space-between;
    max-width: 400px;
    margin: 0 auto;
  }
}