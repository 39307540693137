.button {
  font-family: "Roboto Flex";
  border-radius: 4px;
  color: var(--WHITE, #FFF);
  font-size: 15px;
  padding: 18px 32px;
  text-transform: uppercase;
  text-shadow: 0px 0px 20px rgba(159, 109, 24, 0.65);
  font-weight: 700;
  text-decoration: none;
}

.button_yellow {
  border-radius: 4px;
  background: linear-gradient(180deg, rgba(249, 168, 53, 0.00) 38.54%, #CD7900 100%), #F8A834;
  border: none;
}

.button_white {
  border-radius: 4px;
  border: 1px solid #FFF;
  background: rgba(255, 255, 255, 0.10);
}

.button__file {
  width: 1px;
  height: 1px;
  overflow: hidden;
}
