.imageChangeWrap {
  border: 1px solid red;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.imageChangeWrap__label {
  cursor: pointer;
  justify-content: center;
  display: flex;
}