.photo {
  gap: 13px;
  display: flex;
  /* justify-content: center; */
  width: 100%;
  margin: 20px 0px;
  flex-direction: column;
  align-items: center;
}

.button {
  width: fit-content;  
}