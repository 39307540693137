.finalScreen {
  margin-top: 119px;
}

.header {
  color: #FFF;
  text-align: center;
  font-family: "Roboto Flex";
  font-size: 24px;
  margin-bottom: 20px;
}