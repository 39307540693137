.phrase {
  /* margin-bottom: 40px; */
  margin-top: 108px;
  max-width: 400px;
  margin: 0 auto;
}

.header {
  margin-bottom: 36px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 48px;
  margin-bottom: 39.5px;
}

.phrase__item {
  border-radius: 4px;
  background: none;
  border: none;
  /* padding: 20px 40px 20px 20px; */
  text-align: left;
  font-weight: 600;
}

.phrase__item_selected {
  background: #F8A734;
}

:global(.phrase) {
  fill: #FFF;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.4px;
  dominant-baseline: hanging;
  transform-origin: center;
  letter-spacing: 0.4px;
}

.item {
  letter-spacing: 0.4px;
  color: #FFF;
  font-family: Roboto;
  font-size: 20px;
  width: 100%;
  white-space: pre-wrap;
  display: block;
}

.list__expand {
  background: none;
  border: none;
  color: #FFF;
  text-align: left;
  color: #F8A734;
  text-shadow: 0px 0px 20px rgba(159, 109, 24, 0.65);
  font-size: 20px;
  font-weight: 600;
  font-style: italic;
  cursor: pointer;
  display: flex;
  align-items: stretch;
  gap: 6px;
}

.createModal {
  top: 0px;
}

.createModal__text {
  
}



@media only screen and (min-width: 992px) {
.phrase {
    /* margin-bottom: 40px; */
    margin-top: 108px;
    max-width: 400px;
    margin: 0 auto;
    margin-top: 108px;
  }
}