.hideContent {
  position: relative;
  padding-top: 10px;
}

.hideContent_hide {
  display: none;
}

.hideContent__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* opacity: 0.7; */
  background: rgba(0, 0, 0, 0.50);
  backdrop-filter: blur(3.5px);
  z-index: 99;
}

.header {
  color: #FFF;
  z-index: 1;
  position: absolute;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 11px;
  padding-top: 150px;
  top: 0px;
  z-index: 2;
  font-size: 26px;
  z-index: 999;
}