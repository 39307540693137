.editorHeader {
  padding-top: 30px;
  padding-bottom: 48px;
  background: 
  linear-gradient(180deg, rgba(0, 0, 0, 0.90) 0%, rgba(0, 0, 0, 0.00) 100%),
  url(../assets/bg-header.png);
}

.header {
  color: #FFF;
  margin: 0 auto;
  font-family: "Roboto Flex";
  max-width: 250px;
  margin-bottom: 19px;
  text-transform: uppercase;
}

.controls {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-bottom: 20px;
} 

.control__item {
  border-radius: 60px;
  background: rgba(22, 22, 22, 0.52);
  width: 54px;
  height: 54px;
  /* padding: 14.5px; */
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;

}

.random {

}