.editableWrap {
  position: relative;
}

.icon {
  font-size: 15px;
  width: 20px;
}

.controls {
  top: 0px;
  left: 0px;
  gap: 3px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  /* height: 30px; */
  /* height: 10px; */
}

.submit {
   width: 15px;
}

.controls__form {
  display: flex;
  justify-content: center;
  align-items: stretch;
  /* border: 1px solid black; */
}

.controls__orderVal {
  width: 25px;
  height: auto;
  /* padding: 0px 3px !important; */
}

.button {
  padding: 0px 4px !important;
  isplay: flex;
  align-items: center;
  justify-content: center;
}