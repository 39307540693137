.modal {
  width: 100%;

  height: var(--app-height);
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9000;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
}

.display_hide {
  display: none;
}

@media only screen and (max-width: 991px) {
  .modal {
    max-width: 400px;
  }
}


@media only screen and (min-width: 992px) {
  .modal {
    max-width: 800px;
  }
}