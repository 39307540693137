.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
}

.bg {
  width: 100vw;
  height: var(--app-height);
  position: fixed;
  top: 0;
  /* left: 0; */
  background-image: url(../assets/bg.jpg);
  background-size: cover;
  /* background-repeat: no-repeat; */
  z-index: -1;
}

.header {
  padding-top: 21px;
  padding-bottom: 28px;
}

.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  padding-bottom: 50px;
}

.label {
  color: #FFF;
  text-align: center;
  font-family: "Roboto Flex";
  font-size: 6vw;
  font-weight: 720;
  letter-spacing: 1.4px;
}

.main {
  flex-grow: 1;
}


@media only screen and (max-width: 991px) {
  .layout {
    max-width: 400px;
  }

  .bg {
    max-width: 400px;
  }
}


@media only screen and (min-width: 992px) {
  .layout {
    max-width: 800px;
  }

  .bg {
    max-width: 800px;
    /* display: none; */
  }
}