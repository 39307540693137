.character {
  margin-bottom: 88px;
  margin-top: 84px;
}

.header {
  color: #FFF;
  text-align: center;
  font-family: "Roboto Flex";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
  margin: 0;
  margin-bottom: 18px;
  margin-bottom: 28px !important;
}

.list {
  display: flex;
  overflow: scroll;
  gap: 20px;
}

.imgItem {
  background: none;
  border: none;
  padding: 0;
}

.item {
  border-radius: 10px;
  background: #242437;
  width: 248px;
  opacity: 0.7; 
}

.item__selected {
  opacity: 1;
}