.footer {
  margin-top: 90px;
  text-align: center;
}

.social {
  display: flex;
  justify-content: center;
  position: relative;
  top: -10px;
}

.logo {
  margin-bottom: 20px;
}

.social__icon {
  margin: 0 10px;
  width: 24px;
  height: 24px;

}

.footer__rect {
  width: 100%;
  height: 157px;
  /* background: #000; */
  /* font-family: Eurostile; */

  display: flex;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.30);
  flex-direction: column;
  align-items: center;
  
}

.footer__rect__recaptcha {
  margin-top: 10px;
  max-width: 90%;
  display: flex;
  margin: 10px 30px;
  flex-direction: column;
}

.link {
  color: rgba(255, 255, 255, 0.30);
  text-decoration: none;
}