.adminControl {
  position: fixed;
  right: 0;
  top: 0;
}


.deployButton {
  position: fixed;
  left: 0;
  background-color: #8a1c1c;
  color: white;
  font-weight: 500;
}