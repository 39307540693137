.sticker {
  padding-top: 54px;
  margin-bottom: 37px;
}

.header {
  color: #FFF;
  text-align: center;
  font-family: "Roboto Flex";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
  margin: 0;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.tip {
  color: #FFF;
  text-align: center;
  font-family: "Roboto Flex";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.7px;
  margin: 0;
  margin-bottom: 25px;
}

.imgItem {
  background: none;
  border: none;
  padding: 0;
  /* width : 30%; */
}

.imgItem__img {
  width : max(20vw, 135px);
  opacity: 0.6;
  width: 140px;
}

.imgItem__img_selected {
  opacity: 1;
}

.list {
  display: flex;
  overflow: scroll;
  gap: 3vw;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}