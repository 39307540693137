.listOfItems{

}

.uploadButton{
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  bacground-color: #f0f0f0;
  margin-bottom: 10px;
}