.editor {
  top: 0;
  left: 0;
  margin: 0 auto;
  /* width: calc(min(100vw, 100vh) / 0.65);
  height: calc(min(100vw, 100vh) / 0.65); */

  width: calc(min(100vw, 400px) - 30px);
  height: calc((min(100vw, 400px) - 30px) * 1.7793);

  display: flex;
  /* background-image: url(../assets/bg.svg) */
}

.header {
  margin-bottom: 10px;
  text-align: left;
  font-size: 16px;
}

.editor svg {
  background-color: white;
  border-radius: 8px;
}

.area {
  position: relative;
  flex-grow: 1;
}

:global(.pic) {
  outline: gray solid 0px;
  transform-origin: center;
  transform-box: fill-box;
}

:global(.pic_loading) {
  animation-duration: 0.5s;
  animation-name: changeopacity;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes changeopacity {
  from {
    opacity: 0.5;
  }

  to {
    width: 0.3;
  }
}


:global(.pic_selected) {
  outline: gray solid 1px;
}

.wrap {
  /* background: url(../assets/background.jpeg); */
  display: flex;
  /* gap: 30px; */
  flex-direction: column;
}

.layers {
  /* position: absolute; */
  top: 10px;
  right: 10px;
  min-width: 200px;
  z-index: 20;
  margin-top: 30px;
  margin-right: 10px;
}

/* .remove {
  cursor: pointer;
  border-radius: 4px;
  background: #363636;
} */

:global(.rotate) {
  display: none;
}

:global(.resize) {
  display: none;
}

.layer_selected {

}

.editor__confirmPhoto {
  position: absolute;
  bottom: 16px;
  left: 0;
  width: 100%;
}

:global(.layer_selected) :global(.rotate) {
  /* display: block; */
  display: none;
}

:global(.layer_selected) :global(.resize) {
  /* display: block; */
  display: none;
}