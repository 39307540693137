.locale {
  position: absolute;
  right: 0px;
  top: 64px;
  z-index: 9999999;
  border-radius: 5px 0px 0px 5px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.current {
  border-radius: 5px 0px 0px 5px;
  background: rgba(8, 11, 14, 0.50);
  padding: 10px 5px 11px 7px;
}

.item {
  /* display: flex; */
  gap: 9px;
  padding: 10px 13px;
  background: none;
  border: 0;
  background: #080B0E;
  display: none;
}

.item__flag {
  width: 20px;
}


.item__name {
  color: #FFF;
  font-family: "Roboto Flex";
  font-size: 14px;
  color: #FFF;
  text-transform: uppercase;
  font-weight: 700;
}

.locale:hover .item {
  display: flex;
}

.locale:hover .current {
  display: none;
}