.TranslateWrap {

}

.TranslateWrap_higlight {
  border: 1px solid black;
}

.KeyLink {
  color: blue;
  cursor: pointer;
  background: none;
  border: none;
}