.postcard {
  width: calc(min(100vw, 400px) - 30px);
  height: calc((min(100vw, 400px) - 30px)* 1.7793);
  margin: 0 auto;
  margin-top: 14px;
}

.image {
  width: 100%;
  margin-bottom: 16px;
  border-radius: 8px;
}

.tag {
  color: #FFF;
  text-align: center;
  font-family: "Roboto Flex";
  font-size: 20px;
  margin-top: 14px;
  margin-bottom: 20px;
}

.controls {
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: stretch;
  margin: 0 3%;
}

.button {
  color: var(--WHITE, #FFF);
  text-align: center;
  text-shadow: 0px 0px 20px rgba(159, 109, 24, 0.65);
  font-family: "Roboto Flex";
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* max-width: 323px; */
}

.button__icon {
  height: 100%;
}